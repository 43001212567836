import React from 'react';
import FeaturedProducts from '../../my-components/FeaturedProducts/FeaturedProducts';
import banner1 from '../../assets/homepage/tesla_battery.png';

const Tesla = () => {
    const slides = [
        {
            imageUrl: banner1,
            title: 'Tesla  - Battery',
            description: "The Tesla Powerwall is a rechargeable lithium-ion battery energy storage product designed for residential and light commercial use. It stores electricity generated from solar panels or from the grid during off-peak times for use during peak demand periods or when the grid is down. The Powerwall can also be used to power essential appliances during power outages, providing backup power and increasing energy independence. Its sleek design and smart energy management features make it a popular choice for homeowners looking to reduce their reliance on traditional utility power and embrace clean energy solutions.",
            link: '/products/tesla-solar'
        }
    ];

    return (
        <div>
            <FeaturedProducts customSlides={slides} animation={false} />
        </div>
    )
}

export default Tesla