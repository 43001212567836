import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './TeslaSolar.css';
import TeslaPDPDesk from '../../assets/productpages/REC.jpg';
import TeslaPDPMob from '../../assets/productpages/REC.jpg';
import TeslaMobSwiper1 from '../../assets/productpages/REC.jpg';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import teslaPowerwall from '../../assets/productpages/REC.jpg';

const RECSolar = () => {
  return (
    <div className='product-page-super-parent'>
      <div className="custom-banner-section carousel">
        <div className="list">
          <div className="item" >
          {/* <video autoPlay loop muted controls className='product-main-banner'>
            <source src={teslabanner} type="video/mp4"/>
          </video> */}
          <div className='DesktopBannerTesla'>
            <img src={TeslaPDPDesk} alt='DesktopBanner' width={1920} height={1080}/>
          </div>
          <div className='MobileBannerTesla'>
            <img src={TeslaPDPMob} alt='MobileBanner' width={720} height={1280}/>
          </div>
            <div className="content MobBannerContent">
              <div className="title montserrat-bold">REC Solar Panels</div>
              <div className="des montserrat-regular">REC Solar Panels are a prominent offering from REC Group, a leading European brand known for its high-quality, high-efficiency solar photovoltaic (PV) products. REC Solar Panels are designed to deliver exceptional performance, durability, and reliability, making them a popular choice for a wide range of applications, from residential rooftops to large-scale commercial and utility projects.</div>
              <div class="buttons">
                <Link to='/get-quote'>
                  <button className='lato-bold'>Get Started</button>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='about-section-brand'>
        <section class="elementor-section elementor-top-section elementor-element elementor-element-e4817c9 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e4817c9" data-element_type="section">
          <div class="elementor-container elementor-column-gap-default">
            <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b45e63c" data-id="b45e63c" data-element_type="column">
              <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-9154ea2 elementor-widget elementor-widget-image" data-id="9154ea2" data-element_type="widget" data-widget_type="image.default">
                  <div class="elementor-widget-container DeskTopTesla">
                    <img src={TeslaMobSwiper1} />
                  </div>
                  <div class="elementor-widget-container MobileTesla">
                    <img src={teslaPowerwall} />
                  </div>
                </div>
              </div>
              <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4558e86" data-id="4558e86" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-6b5e14b elementor-widget elementor-widget-heading" data-id="6b5e14b" data-element_type="widget" data-widget_type="heading.default">
                    <div class="elementor-widget-container">
                      <h2 class="elementor-heading-title elementor-size-default">Powering Your Future with Precision and Sustainability</h2>		</div>
                  </div>
                  <div class="elementor-element elementor-element-3e6203e elementor-widget elementor-widget-text-editor" data-id="3e6203e" data-element_type="widget" data-widget_type="text-editor.default">
                    <div class="elementor-widget-container">
                      <p>REC Solar Panels stand at the forefront of solar technology, embodying the perfect blend of precision engineering, high efficiency, and environmental responsibility. Manufactured by REC Group, a leading European solar energy brand, these panels are designed to deliver unparalleled performance across various applications, from residential rooftops to vast utility-scale projects. With efficiency rates often exceeding 20%, REC Solar Panels ensure that more sunlight is converted into clean, renewable energy, maximizing your power output and reducing your electricity costs.</p>						
                      <p> </p>
                      <p>Incorporating advanced cell technologies such as Heterojunction (HJT) and Passivated Emitter and Rear Cell (PERC), REC panels enhance light capture and minimize energy losses. The innovative split cell and Twin Design further boost performance by reducing resistance losses and improving shade tolerance. Built to withstand the harshest environmental conditions, these panels offer exceptional durability and reliability, backed by comprehensive warranties of up to 25 years.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <div className='product-usp-sectionDesk'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={TeslaDeskSwiper2} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className='product-usp-sectionMob'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} alt='MobileSwiper1' />
          </SwiperSlide>
          <SwiperSlide>
          <img  src={TeslaMobSwiper2} alt='MobileSwiper2' />
          </SwiperSlide>
        </Swiper>
      </div> */}
      <div className='product-specification'>
        <h2>REC Specification</h2>
        <div className='specification-parent'>
          <div className='specification-child'>
            <h3>Power</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Watt</h5>
                <p>410</p>
              </div>
              <div className='spec-card'>
                <h5>Cell Type</h5>
                <p>132 half-cut bifacial REC heterojunction cells, with lead-free
                technology</p>
              </div>
              <div className='spec-card'>
                <h5>Glass</h5>
                <p>3.2 mm solar glass with anti-reflective surface treatment
                in accordance with EN12150</p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Features</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Backsheet</h5>
                <p>Highly resistant polymer (Black)</p>
              </div>
              <div className='spec-card'>
                <h5>Frame</h5>
                <p>Anodized aluminum (Black)</p>
              </div>
              <div className='spec-card'>
                <h5>Dimensions</h5>
                <p>1821 x 1016 x 30 mm (1.85 m²)</p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Warranty</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Duration</h5>
                <p>25 years</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RECSolar