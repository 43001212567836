import React from 'react';
import FeaturedProducts from '../../my-components/FeaturedProducts/FeaturedProducts';
import banner1 from '../../assets/homepage/enphase_invertors.webp';

const Enphase  = () => {
    const slides = [
        {
            imageUrl: banner1,
            title: 'Enphase - MicroInverter',
            description: "The high-powered, smart grid-ready IQ8HC Microinverters are designed to match the latest generation high output PV modules. The IQ8HC Microinverters have the highest energy production and reliability standards in the industry, and with rapid shutdown functionality, it meets the highest safety standards. The brain of the semiconductor-based microinverter is our proprietary, application-specific integrated circuit (ASIC) that enables the microinverter to operate in a grid-connected mode. IQ8 Series Microinverters redefine reliability standards with more than one million cumulative hours of power-on testing, enabling an industry-leading limited warranty of up to 25 years.",
            link: '/products/enphase-invertor'
        }
    ];

    return (
        <div>
            <FeaturedProducts customSlides={slides} animation={false} />
        </div>
    )
}

export default Enphase 