import React from 'react';
import FeaturedProducts from '../../my-components/FeaturedProducts/FeaturedProducts';
import banner1 from '../../assets/homepage/byd_battery.png';

const Byd = () => {
    const slides = [
        {
            imageUrl: banner1,
            title: 'BYD  - Battery',
            description: "The new BYD Battery-Box Premium HVS 7.7 battery storage system generation builds on the well-known memories and has all previous functions. The Battery-Box Premium HVS is a battery module that has higher storage capacities than its predecessor. The further development of cell technology has reduced the system weight by almost 30%. The construction depth was also reduced by 8 cm. This means that less floor space is required. At the same time, the new devices have a leaner look. One of the highlights: The new storage systems have 'protection class IP55'. This enables use outside as well.",
            link: '/products/byd-battery'
        }
    ];

    return (
        <div>
            <FeaturedProducts customSlides={slides} animation={false} />
        </div>
    )
}

export default Byd