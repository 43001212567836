import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './TeslaSolar.css';
import TeslaPDPDesk from '../../assets/productpages/Trina_Solar.jpg';
import TeslaPDPMob from '../../assets/productpages/Trina_Solar.jpg';
import TeslaMobSwiper1 from '../../assets/productpages/Trina_Solar.jpg';
import TeslaMobSwiper2 from '../../assets/productpages/powerwall2img2mob.jpg'
import TeslaDeskSwiper1 from '../../assets/productpages/powerwall2img1.jpg'
import TeslaDeskSwiper2 from '../../assets/productpages/powerwall2img2.jpg'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { EffectFade, Navigation, Pagination } from 'swiper/modules';

import teslaPowerwall from '../../assets/productpages/Trina_Solar.jpg';

const TrinaSolar = () => {
  return (
    <div className='product-page-super-parent'>
      <div className="custom-banner-section carousel">
        <div className="list">
          <div className="item" >
          {/* <video autoPlay loop muted controls className='product-main-banner'>
            <source src={teslabanner} type="video/mp4"/>
          </video> */}
          <div className='DesktopBannerTesla'>
            <img src={TeslaPDPDesk} alt='DesktopBanner' width={1920} height={1080}/>
          </div>
          <div className='MobileBannerTesla'>
            <img src={TeslaPDPMob} alt='MobileBanner' width={720} height={1280}/>
          </div>
            <div className="content MobBannerContent">
              <div className="title montserrat-bold">Trina Solar Panels</div>
              <div className="des montserrat-regular">Trina Solar Panels are among the most recognized and respected products in the solar energy industry, manufactured by Trina Solar, a leading global provider of photovoltaic (PV) modules and smart energy solutions. Trina Solar has built a reputation for producing high-quality, reliable, and efficient solar panels that cater to a wide range of applications, including residential, commercial, and utility-scale projects. </div>
              <div class="buttons">
                <Link to='/get-quote'>
                  <button className='lato-bold'>Get Started</button>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='about-section-brand'>
        <section class="elementor-section elementor-top-section elementor-element elementor-element-e4817c9 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e4817c9" data-element_type="section">
          <div class="elementor-container elementor-column-gap-default">
            <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b45e63c" data-id="b45e63c" data-element_type="column">
              <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-9154ea2 elementor-widget elementor-widget-image" data-id="9154ea2" data-element_type="widget" data-widget_type="image.default">
                  <div class="elementor-widget-container DeskTopTesla">
                    <img src={TeslaMobSwiper1} />
                  </div>
                  <div class="elementor-widget-container MobileTesla">
                    <img src={teslaPowerwall} />
                  </div>
                </div>
              </div>
              <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4558e86" data-id="4558e86" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-6b5e14b elementor-widget elementor-widget-heading" data-id="6b5e14b" data-element_type="widget" data-widget_type="heading.default">
                    <div class="elementor-widget-container">
                      <h2 class="elementor-heading-title elementor-size-default">Harnessing the Power of Tomorrow, Today</h2>		</div>
                  </div>
                  <div class="elementor-element elementor-element-3e6203e elementor-widget elementor-widget-text-editor" data-id="3e6203e" data-element_type="widget" data-widget_type="text-editor.default">
                    <div class="elementor-widget-container">
                      <p>Trina Solar Panels stand as a testament to cutting-edge solar technology and reliable performance. Crafted by Trina Solar, a global leader in photovoltaic solutions, these panels are designed to deliver exceptional energy efficiency and durability. With efficiency rates often exceeding 20%, Trina Solar Panels ensure that every ray of sunlight is harnessed to its fullest potential, making them ideal for a wide range of applications, from residential rooftops to extensive utility-scale projects.</p>						
                      <p> </p>
                      <p>Trina Solar’s commitment to sustainability is evident in their low degradation rates and eco-friendly manufacturing processes. These panels maintain high energy output over their lifespan, backed by comprehensive warranties of up to 25 years. This longevity and reliability make Trina Solar Panels a cost-effective choice, offering substantial savings over time while reducing carbon footprints. Whether for homes, businesses, or large solar farms, Trina Solar Panels provide a dependable, high-performance solution for clean, renewable energy.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <div className='product-usp-sectionDesk'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={TeslaDeskSwiper2} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className='product-usp-sectionMob'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} alt='MobileSwiper1' />
          </SwiperSlide>
          <SwiperSlide>
          <img  src={TeslaMobSwiper2} alt='MobileSwiper2' />
          </SwiperSlide>
        </Swiper>
      </div> */}
      <div className='product-specification'>
        <h2>Trina Specification</h2>
        <div className='specification-parent'>
          <div className='specification-child'>
            <h3>Power</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Watt</h5>
                <p>450W</p>
              </div>
              <div className='spec-card'>
                <h5>Module efficiency</h5>
                <p>22.5%</p>
              </div>
              <div className='spec-card'>
                <h5>Positive Power Tolerance</h5>
                <p>0/+5 W</p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Features</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Module Dimensions</h5>
                <p>1762 ×1134×30 mm</p>
              </div>
              <div className='spec-card'>
                <h5>Number of cells</h5>
                <p>144 cells</p>
              </div>
              <div className='spec-card'>
                <h5>Front Glass </h5>
                <p>1.6 mm, High Transmission, AR Coated Heat Strengthened Glass</p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Warranty</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Duration</h5>
                <p>25 years</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TrinaSolar