import React from 'react';
import FeaturedProducts from '../../my-components/FeaturedProducts/FeaturedProducts';
import banner1 from '../../assets/homepage/reclaim_heatpump1.png';

const Reclaim = () => {
    const slides = [
        {
            imageUrl: banner1,
            title: 'Reclaim  - Heat Pump',
            description: "Reclaim Energy’s high efficiency Q-TON Commercial Heat Pump is an air-to-water heat pump using CO2 gas as a refrigerant. It can be used in a variety of applications for the supply of sanitary hot water. The Q-TON Commercial Heat Pump is the world’s first two-stage compressor combining rotary and scroll technology to maintain high efficiency and significantly improve performance at cooler air temperatures.",
            link: '/products/reclaim-heatpump'
        }
    ];

    return (
        <div>
            <FeaturedProducts customSlides={slides} animation={false} />
        </div>
    )
}

export default Reclaim