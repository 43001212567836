import React from 'react';
import FeaturedProducts from '../../my-components/FeaturedProducts/FeaturedProducts';
import banner1 from '../../assets/homepage/fronius_gen24.webp';

const Fronius = () => {
    const slides = [
        {
            imageUrl: banner1,
            title: 'Fronius - Inverter',
            description: "Fronius inverters are efficient and reliable and form the indispensable heart of every photovoltaic system. Power categories ranging from 1.5 to 27.0 kW guarantee suitability for virtually any system size – from a family home to a large-scale system.",
            link: '/products/fronius-invertor'
        }
    ];

    return (
        <div>
            <FeaturedProducts customSlides={slides} animation={false} />
        </div>
    )
}

export default Fronius