import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './TeslaSolar.css';
import TeslaPDPDesk from '../../assets/productpages/Enphase1.jpg';
import TeslaPDPMob from '../../assets/productpages/Enphase1.jpg';  
import TeslaMobSwiper1 from '../../assets/productpages/Enphase2.jpg';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import teslaPowerwall from '../../assets/productpages/Enphase2.jpg';

const EnphaseInvertor = () => {
  return (
    <div className='product-page-super-parent'>
      <div className="custom-banner-section carousel">
        <div className="list">
          <div className="item" >
          {/* <video autoPlay loop muted controls className='product-main-banner'>
            <source src={teslabanner} type="video/mp4"/>
          </video> */}
          <div className='DesktopBannerTesla'>
            <img src={TeslaPDPDesk} alt='DesktopBanner' width={1920} height={1080}/>
          </div>
          <div className='MobileBannerTesla'>
            <img src={TeslaPDPMob} alt='MobileBanner' width={720} height={1280}/>
          </div>
            <div className="content MobBannerContent">
              <div className="title montserrat-bold">Enphase MicroInverters</div>
              <div className="des montserrat-regular">Enphase inverters are advanced microinverters designed for solar energy systems. Unlike traditional string inverters, each Enphase inverter works with individual solar panels, converting DC power from each panel to AC power directly at the source. This technology allows for greater efficiency, flexibility, and optimization of solar energy production. Enphase inverters also enable detailed monitoring of system performance at the panel level, improving reliability and simplifying maintenance.</div>
              <div class="buttons">
                <Link to='/get-quote'>
                  <button className='lato-bold'>Get Started</button>
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='about-section-brand'>
        <section class="elementor-section elementor-top-section elementor-element elementor-element-e4817c9 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="e4817c9" data-element_type="section">
          <div class="elementor-container elementor-column-gap-default">
            <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-b45e63c" data-id="b45e63c" data-element_type="column">
              <div class="elementor-widget-wrap elementor-element-populated">
                <div class="elementor-element elementor-element-9154ea2 elementor-widget elementor-widget-image" data-id="9154ea2" data-element_type="widget" data-widget_type="image.default">
                  <div class="elementor-widget-container DeskTopTesla">
                    <img src={TeslaMobSwiper1} />
                  </div>
                  <div class="elementor-widget-container MobileTesla">
                    <img src={teslaPowerwall} />
                  </div>
                </div>
              </div>
              <div class="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4558e86" data-id="4558e86" data-element_type="column">
                <div class="elementor-widget-wrap elementor-element-populated">
                  <div class="elementor-element elementor-element-6b5e14b elementor-widget elementor-widget-heading" data-id="6b5e14b" data-element_type="widget" data-widget_type="heading.default">
                    <div class="elementor-widget-container">
                      <h2 class="elementor-heading-title elementor-size-default">Small things make a big difference</h2>		</div>
                  </div>
                  <div class="elementor-element elementor-element-3e6203e elementor-widget elementor-widget-text-editor" data-id="3e6203e" data-element_type="widget" data-widget_type="text-editor.default">
                    <div class="elementor-widget-container">
                      <p>Enphase IQ Microinverters are named after their highly intelligent microchip. We customise our chips to maximise the size, weight, reliability, and performance of our products. Enphase Energy Systems are based on a distributed architecture. This modular microinverter design means you can easily expand a system as home energy needs grow. No single point of failure. If a system with a string inverter fails, solar production will stop completely. With one microinverter per module, solar production keeps working even if a microinverter fails.</p>						
                      <p> </p>
                      <p>IQ Microinverters are IP67 certified with a double-insulated, corrosion-resistant polymer housing, so they’re ready for just about anything Mother Nature can throw at them. Enphase IQ8 Series Microinverters are covered by a 15-year limited warranty, extendable to 20 or 25 years.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <div className='product-usp-sectionDesk'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} />
          </SwiperSlide>
          <SwiperSlide>
            <img src={TeslaDeskSwiper2} />
          </SwiperSlide>
        </Swiper>
      </div>
      <div className='product-usp-sectionMob'>
        <Swiper
          spaceBetween={30}
          effect={'fade'}
          navigation={true}
          pagination={{
            clickable: true,
          }}
          modules={[EffectFade, Navigation, Pagination]}
          className="product-usp-section-mySwiper carousel"
        >
          <SwiperSlide>
            <img src={TeslaDeskSwiper1} alt='MobileSwiper1' />
          </SwiperSlide>
          <SwiperSlide>
          <img  src={TeslaMobSwiper2} alt='MobileSwiper2' />
          </SwiperSlide>
        </Swiper>
      </div> */}
      <div className='product-specification'>
        <h2>Enphase Specification</h2>
        <div className='specification-parent'>
          <div className='specification-child'>
            <h3>Power</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Microinverter Technology</h5>
                <p>Converts DC power from individual solar panels into AC power</p>
              </div>
              <div className='spec-card'>
                <h5>Power Output</h5>
                <p>From 200W to 360W per microinverter</p>
              </div>
              <div className='spec-card'>
                <h5>Efficiency</h5>
                <p>97% or higher efficiency</p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Features</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Output Voltage</h5>
                <p>240V AC</p>
              </div>
              <div className='spec-card'>
                <h5>Temperature Range </h5>
                <p>-40°C to 65°C</p>
              </div>
              <div className='spec-card'>
                <h5>Size and Weight</h5>
                <p>Compact and lightweight design </p>
              </div>
            </div>
          </div>
          <div className='specification-child'>
            <h3>Warranty</h3>
            <div className='specs-details'>
              <div className='spec-card'>
                <h5>Duration</h5>
                <p>15 years</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnphaseInvertor